<template>
  <div
    id="login-page"
    class="h-100 page d-flex flex-column justify-content-center p-3"
  >
    <div>
      <login-page @login="onLogin" />
      <small class="d-block text-center mb-3 mt-4">
        <b-link class="text-center d-block text-muted" :to="{ name: 'list' }">
          Plus tard
        </b-link>
      </small>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if (this.authenticated) {
      this.$router.push({ name: "list" })
    }
  },
  methods: {
    onLogin(token, recentlyCreated) {
      if (recentlyCreated) {
        this.$router.push({ name: "geoloc" })
      } else {
        this.$router.push({ name: "list" })
      }
    }
  }
}
</script>
